/* Estilos para a Home */
.home-page .intro-section {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.6)), url('/src/assets/image/Home/frentehotel4.png');
  background-size: cover;
  background-position: center;
}

.hotel-cor{
  color: #ffc107;
}
.home-page .intro-content h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.8);
}

.home-page .intro-content p {
  padding: 10px;
}

.home-page .saiba-mais-btn {
  padding: 0.5rem 2rem;
  background-color: #c8b051;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.home-page .saiba-mais-btn:hover {
  background-color: #9f7634;
}

/* Serviços */
.home-page .services {
  padding: 3rem 0;
  background-color: #f9f9f9;
}

.home-page .services .icon-box {
  padding: 2rem;
  background: #fff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.home-page .services .icon-box:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
}

.home-page .services .icon {
  margin-bottom: 1rem;
  color: #c8b051;
}

.home-page .services h4 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #333;
}

.home-page .services p {
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
  margin-bottom: 0;
}

/* Responsivo */
@media (min-width: 768px) and (max-width: 991px) {
  .home-page .services .icon-box {
    width: 90%;
    margin: 0 auto;
  }
}
a{
  color: #333;
}
.card-ap{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.card-ap .colunas{
  padding-top: 15px;
  padding-bottom: 10px;
  display: flex ;
  justify-content: center;
}
ul li {
  list-style: none;
  text-align: left;
}

.container .contato {
  padding-top: 30px;
  text-align: center;
}

hr {
  background-color: #333;
  padding: 1px;
}

.list-itens ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Permite quebrar os ícones em várias linhas */
  gap: 20px; /* Espaçamento entre os itens */
}

.list-itens ul li {
  padding: 0 20px; /* Reduz o espaçamento lateral */
  font-size: 30px; /* Reduz o tamanho do ícone para telas menores */
}

.fonts {
  font-family: 'Times New Roman', Times, serif;
  color: #615226;
}

@media (max-width: 768px) {
  .list-itens ul {
    flex-direction: column; /* Coloca os ícones em uma coluna */
    align-items: center; /* Centraliza os itens */
  }

  .list-itens ul li {
    padding: 10px 0; /* Adiciona espaçamento vertical entre os ícones */
    font-size: 25px; /* Reduz o tamanho do ícone ainda mais em telas menores */
  }

  .container .contato {
    padding-top: 20px;
  }

  .fonts {
    font-size: 16px; /* Ajusta o tamanho do texto */
    padding: 0 15px; /* Adiciona um pouco de espaçamento lateral */
  }
}
.card-img-top {
  object-fit: cover;
  height: 300px;
  width: 100%;
}

.card {
  overflow: hidden;
  position: relative;
}


