.intro-image {
  border-radius: 10px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.15);
  object-fit: cover;
}
/* Estilo para cobrir 100% da altura da página */
.intro-section {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff; 
  padding: 2rem;
}

.features-list li::before {
  content: "✓";
  position: absolute;
  left: 0;
  color: #c8b051;
  font-size: 1.2rem;
}
.centered{
  text-align: center;
  padding: 10px 0px;
}

.vai-pra-la hr {
  padding: 0.5px;
  background-color: #c8b051;
  color: #c8b051;
  width: 300px;
}
.vai-pra-la {
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
}
.tam{
  hr{
    width: 500px;
  }
}

/* Personalizar o ícone da seta para a esquerda */
.carousel-control-prev-icon {
  background-color: #c8b051; /* Cor de fundo da seta */
  background-image: none; /* Remove o ícone padrão */
  mask-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="%23fff" viewBox="0 0 16 16"%3E%3Cpath fill-rule="evenodd" d="M11.354 1.354a.5.5 0 0 1 0 .708L5.207 8.5l6.147 6.146a.5.5 0 0 1-.708.708l-6.5-6.5a.5.5 0 0 1 0-.708l6.5-6.5a.5.5 0 0 1 .708 0z"/%3E%3C/svg%3E');
  -webkit-mask-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="%23fff" viewBox="0 0 16 16"%3E%3Cpath fill-rule="evenodd" d="M11.354 1.354a.5.5 0 0 1 0 .708L5.207 8.5l6.147 6.146a.5.5 0 0 1-.708.708l-6.5-6.5a.5.5 0 0 1 0-.708l6.5-6.5a.5.5 0 0 1 .708 0z"/%3E%3C/svg%3E');
  background-size: 50%; /* Ajuste do tamanho da seta */
  background-repeat: no-repeat;
  background-position: center;
  width: 3rem; /* Tamanho da seta */
  height: 3rem;
  border-radius: 50%; /* Deixa o fundo arredondado */
}

/* Personalizar o ícone da seta para a direita */
.carousel-control-next-icon {
  background-color: #c8b051; /* Cor de fundo da seta */
  background-image: none; /* Remove o ícone padrão */
  mask-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="%23fff" viewBox="0 0 16 16"%3E%3Cpath fill-rule="evenodd" d="M4.646 1.354a.5.5 0 0 1 .708 0l6.5 6.5a.5.5 0 0 1 0 .708l-6.5 6.5a.5.5 0 0 1-.708-.708L10.793 8.5 4.646 2.354a.5.5 0 0 1 0-.708z"/%3E%3C/svg%3E');
  -webkit-mask-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="%23fff" viewBox="0 0 16 16"%3E%3Cpath fill-rule="evenodd" d="M4.646 1.354a.5.5 0 0 1 .708 0l6.5 6.5a.5.5 0 0 1 0 .708l-6.5 6.5a.5.5 0 0 1-.708-.708L10.793 8.5 4.646 2.354a.5.5 0 0 1 0-.708z"/%3E%3C/svg%3E');
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}

/* Adicionar efeito hover */
.carousel-control-prev:hover .carousel-control-prev-icon,
.carousel-control-next:hover .carousel-control-next-icon {
  background-color: #e0b01c; /* Cor de fundo ao passar o mouse */
}
