.filters {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.filter-btn {
  padding: 0.5rem 1rem;
  border: none;
  background: #515050;
  color: #fdfdfd;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.filter-btn.active {
  background: #010101;
  color: #ffffff;
}

.filter-btn:hover {
  background: #42413f;
}

.room-card {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.room-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.room-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
}
.modal-image {
  max-width: 100%; 
  max-height: 400px; 
  object-fit: cover; 
  margin: 0 auto; 
}
.room-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffc107;
  font-size: 2rem;
  transition: opacity 0.3s ease;
}


.room-title {
  font-size: 1rem;
  color: #fff;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  font-weight: bold;
}

.room-plus {
  font-size: 1rem;
  font-weight: bold;
}

.parent-duvida {
  display: flex;
  justify-content: center; /* Centraliza horizontalmente o item */
  align-items: center; /* Centraliza verticalmente o item */
}
.ajuste-icon{
  font-size: 20px;
}
.duvidas {
  display: flex;
  flex-direction: column;
  width: 50%;
  color: rgb(0, 0, 0);
  text-align: center;
  border-radius: 30px;
  justify-content: center; 
  align-items: center; 
}

.op{
  padding: 10px;
  margin: 0;
  font-family:'Times New Roman', Times, serif;
}

.btn-quartos{
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #61d637;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  
}
.anima-quarto{
  transition: transform 0.3s ease, background-color 0.3s ease;
}
.anima-quarto:hover{
  transform: translateY(-5px); 
}
.btn-quartos:hover{
  transform: translateY(-5px); /* Move o botão para cima */
  background-color: #33b300;  /* Muda a cor do botão */
}
.btn-quartos:focus{
  outline: 2px solid #000000;
}