.contato-page {
  font-family: "Arial", sans-serif;
  background-color: #f9f9f9;
  padding-bottom: 50px;
}

.contato-header {
  font-family:'Times New Roman', Times, serif;
  background-color: #f8f8f8;
  color: rgb(0, 0, 0);
}

.contato-header h1 {
  font-size: 2.5rem;
  font-weight: bold;
}

.contato-header p {
  font-size: 1.2rem;
}

.info-card, .form-card {
  padding: 10px;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.map-container iframe {
  border: none;
  border-radius: 8px;
}
